
import { Excel } from '../../framework/utils'
import { Report } from "../../entities/yearEnd/Report"
import { YearEndService } from "../../services"

const list = (configuration = []) => ({ type: 'list', allowBlank: true, formulae: configuration });

const INSTRUCTIONS_TAB = 'Instructions-READ ';
const YEAR_END_TABS = {
    MEMBER: {
        tabTitle: 'Member Data-REVIEW',
        customLoad: loadSummary,
        startIndex: 3,
    },
    PERSONAL: {
        tabTitle: 'Personal Data-VALIDATE',
        startIndex: 3,
        headerGroups: ['employmentFull', "personalValidation"],
        cellHardConfigs: {
            'Q': { dataValidation: () => list(['"Male,Female"']) },
            'R': { dataValidation: () => list(['"English,French"'])},
            'S': { dataValidation: () => list(['"Yes,No"'])},
            'T': { dataValidation: () => list(['"Yes,No"'])},
            'U': { dataValidation: () => list(['"Yes,No"'])},
        }
    },
    STATUS: {
        tabTitle: 'Status-VALIDATE',
        startIndex: 3,
        headerGroups: ['employmentBrief', 'details', 'detailsFull', "statusValidation"],
        cellHardConfigs: {
            'H': { value: (sheet, row, index) => Math.min(sheet.getCell('H' + (index + 3)).value, 12)}, 
            'J': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$44:$I$60"])}, 
            'L': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$61:$I$65"])},
            'N': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$66:$I$80"])}, 
            'O': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$43:$I$78"]),
                   value: (sheet, row, index) => row.cmt?.richText ? row.cmt.richText.map(comment => comment.text).toString() : row.cmt }, 
            'P': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AE$1:$AE$11"]),
                   value: (sheet, row, index) => row.reviewedCmt },
        },
    },
    ANNUALIZED: {
        tabTitle: 'Annualized Earnings-VALIDATE',
        startIndex: 4,
        headerGroups: ['employmentBrief', 'financialValidation', 'lastYearDetails'],
        cellHardConfigs: {
            'E': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$81:$I$86"])},
        },
    },
    TERMINATION: {
        tabTitle: 'Terminations-CONFIRM',
        startIndex: 5,
        headerGroups: [
            'employmentFullTermTab', 
            'personalInfoValidation', 
            'details', 
            'statusConfirmation',
            'membershipTerminationDateValidation',
            'maritalStatus',
            'pensionableEarningsValidation',
            'contributions',
            'retroactive',
            'totalCreditedService',
        ], 
        cellHardConfigs: {
        // Keeping this in temporarily as we may need them after testing, to remove if this code goes in main
        //     'T': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AD$2:$AD$8"])},
        //     'L': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AD$12:$AD$14"])},
        //     'M': { dataValidation: () => list(['"Yes,No"'])},
        },
        filter: (x) => x.isOnLeaveAndPending
    },
}

function loadSummary (report, sheet, template) {
    let groupHeaders = new Excel.Headers(Report, [
        'employmentFull',
        'details', 
        'pensionableEarnings', 
        'contributions', 
        'creditedService', 
        'pensionAdjustment', 
        'lastYearDetails', 
        'adjustments', 
        'validation',
        'REG',
        'OVR',
        'OTE'
    ])

    groupHeaders = YearEndService.getHeader(YearEndService.displayTypes.SIMPLE, Excel, report.earningTypes, groupHeaders);

    const valueHeaders = groupHeaders.list.reduce((headers, groupHeader) => {
        if(groupHeader.headers) headers = headers.concat(groupHeader.headers.list)
        return headers
    }, [])

    template.addDataRows(sheet, valueHeaders, report.details, 4)

    const earningTypesHeadersStart = 46 //AR
    const summaryHeaderRow = sheet.getRow(1)
    const regCell = summaryHeaderRow.getCell(earningTypesHeadersStart)
    const ovrCell = summaryHeaderRow.getCell(earningTypesHeadersStart + groupHeaders['REG'].headers.list.length)
    const oteCell = summaryHeaderRow.getCell(ovrCell.col + groupHeaders['OVR'].headers.list.length )
    regCell.value = 'Regular Earnings by Pay Code'
    ovrCell.value = 'Overtime Earnings by Pay Code'
    oteCell.value = 'One-Time Earnings by Pay Code'
    ovrCell.style = regCell.style
    oteCell.style = regCell.style
    sheet.mergeCells(1, regCell.col, 1, ovrCell.col - 1 )
    sheet.mergeCells(1, ovrCell.col, 1, oteCell.col - 1 )
    sheet.mergeCells(1, oteCell.col, 1, oteCell.col + groupHeaders['OTE'].headers.list.length - 1)

    const summarySubHeaderRow = sheet.getRow(2)
    valueHeaders.slice(earningTypesHeadersStart - 1).forEach((header, index) => {
        summarySubHeaderRow.getCell(earningTypesHeadersStart + index).value = header.title
        summarySubHeaderRow.getCell(earningTypesHeadersStart + index).style = summarySubHeaderRow.getCell(earningTypesHeadersStart).style
    })
}

export default YEAR_END_TABS;