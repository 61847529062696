import { Service } from '../../framework'
import { Employer } from '../../entities'
import { PersonService, PlanService, SolvencyService } from '..'
import EmployerListMapper from '../../mappers/EmployerListMapper'
import EmployerMapper from '../../mappers/EmployerMapper'

/**
 * @extends {Service}
 */
class EmployerService extends Service {
    constructor() { super(Employer, 'Employer') }
    async get(id, options) { return this.getEmployer(id, options)} //override service.get
    async getAll(options) { return this.getEmployers(options)} //override service.getAll

    async getEmployers(options = {}) { 
        let action = "GetEmployers";
        return await this.callApi(action, action+'_all', null, EmployerListMapper, options);
    }
    
    async getEmployer(employerId, options = {}) {
        return await this.callApi("GetEmployer", employerId, {id: employerId}, EmployerMapper, options);
    }

    async load(employer, options = {}) {
        employer.plan = await PlanService.getPlan(employer.rst, options);
        employer.solvencies = await SolvencyService.getEmployerSolvencies(employer.id, options);

        //load people if not loaded already
        let peopleIds = employer.contacts.map((c) => c.person.id);
        let people =  (await PersonService.getList(peopleIds)).all ?? [];

        employer.contacts.forEach((contact) => (contact.person = people.find((person) => person.id === contact.person.id)));
        return employer;
    }

    validate(employer) {
        var errorMessages = {};
        if (!employer.rst) errorMessages['rst']= 'Invalid jurisdiction';
        if (!employer.code) errorMessages['code']= 'Missing employer code';
        if (!employer.name) errorMessages['name']= 'Missing employer name';
        return errorMessages;
    }

    create(employer) {
        var validationResults = this.validate(employer);
        if (Object.keys(validationResults).length > 0) return { errors: validationResults }

        employer.assignDefaultEarningTypes();

        return this.update(employer);
    }

}

const instance = new EmployerService(Employer)
export default instance
