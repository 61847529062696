import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import { Table } from '../../../framework/controls'
import Loading from '../../../components/containers/Loading'
import PersonalInformationSearch from '../../../services/search/PersonalInformationSearch'
import FilterContainer from '../../../components/containers/FilterContainer'
import _ from 'lodash'
import PersonalInformationView from '../../../entities/search/PersonalInformationSearch/PersonalInformationView'
import { ETS_FORMAT } from '../../../framework/utils/helper';

const FIRST_PAGE = 1;

const PersonalInformationReport = () => {

    let pageSize = PersonalInformationSearch.DEFAUT_PAGE_SIZE;
    const columns = new Table.Headers(PersonalInformationView, 'sin, employer.code, nameOriginal, lastParticipationStatusDesc');
    columns["employer.code"].title = "ER Code";
    const exportConfigs = PersonalInformationView.exportConfigs;
    const history = useHistory();
    const [summaries, setSummaries] = useState();
    const [currentFilters, setCurrentFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState({});

    useEffect(() => {
        loadEmployments(FIRST_PAGE)
    }, [currentFilters]);

    const handleSelectEmployment = (row) => { 
        history.push(`/employer/${row.employer.code}/employment/${row.id}`);
    }

    const loadEmployments = (pageNumber = FIRST_PAGE, scrollDuration, scrollId) => {
        return search({pageNumber, scrollDuration, scrollId}).then((results) => {
            setSummaries(results?.documents?.all);
            setPaginationInfo({total: results.total, totalPages: results.totalPages, currentPage: pageNumber, pageSize });
        });
    }

    const search = async ({pageNumber = FIRST_PAGE, scrollDuration, scrollId}) => {
        if (currentFilters.pageSize) pageSize = parseInt(currentFilters.pageSize);
        const results = await PersonalInformationSearch.search(currentFilters, pageNumber, pageSize, scrollDuration, scrollId);

        return results;
    }

    const onPageChange = (selectedPageNumber) => loadEmployments(selectedPageNumber);

    const postExportFn = ({ excel, sheet, headers}) => {
        const dateFrom = currentFilters.dateFrom ? moment(Number(currentFilters.dateFrom)).format(ETS_FORMAT): 'N/A';
        const dateTo = currentFilters.dateTo ? moment(Number(currentFilters.dateTo)).format(ETS_FORMAT): 'N/A';

        if (currentFilters.dateFrom && currentFilters.dateTo) {
            const message = `Event Date Range: ${dateFrom} to ${dateTo}`;

            excel.addLine(sheet, message);
        }

        return excel;
    }

    const getHeader = (tableType) => {
        const columns = exportConfigs.columns;
        const excelHeaders = new tableType.Headers(PersonalInformationView, columns);

        Object.entries(exportConfigs.headerTitles).forEach(([key, value]) => {
            if (excelHeaders[key]) {
                excelHeaders[key].setTitle(value);
            }
        });

        return excelHeaders;
    }

	return <> 
            {!summaries ? <Loading /> :
                <div className="content-container members-container">
                    <FilterContainer view={PersonalInformationView} onUpdate={setCurrentFilters} exportFn={search}
                        exportHeaderFn={getHeader} postExportFn={postExportFn} filterBoxWidth={300}
                    />
                    <Table id='personal-information-report-table' 
                        data={summaries} 
                        columns={columns} 
                        className="mt-2"
                        editable
                        sort='name'
                        onSelect={handleSelectEmployment}
                        fitHeightToContent
                        isPaginated
                        paginationInfo={{onPageChange, ...paginationInfo }}
                    />
                </div>
            }
    </>;
}

export default PersonalInformationReport;