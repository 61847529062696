import { Definition, RefDated } from '../../../framework/infra'
import { sum, round } from '../../../framework/utils/helper'
import { Period } from '../../../framework/utils'
import Distribution from '../Distribution'
import Adjustments from './Adjustments'
import { Earnings } from '../../financial'

const { AMOUNT, BOOLEAN, PERIOD, DATE, STRING, NUMBER } = Definition.types

export default class Adjustment extends RefDated { //convert to RefMap
    get employerContribution() { return this.getByContributionType('r')} 
    get solvency() { return this.getByContributionType('s')} 
    get interest() { return this.getByContributionType('i')} 
    get employeeReg() { return this.getByContributionType('e')  }
    get employeeVol() { return this.getByContributionType('ev') }
    get employeeMat() { return this.getByContributionType('em') }
    get employeeLtd() { return this.getByContributionType('el') }
    get employeeSlf() { return this.getByContributionType('es') }
    get employeeInterest() { return this.getByContributionType('ei') }
    get interestOnLatePayment() { return this.getByContributionType('ip') }
    
    //Modification with the earning adjustment
    get total () { 
        let total = 0;
        if (this.distributionContribution._list && this.distributionContribution._list.length > 0) {
            total = round(this.distributionContribution._list.reduce((tot, d) => tot += Number(String(d['am']).replace(/[$,]+/g, "")), 0));
        }
        return total;
    }

    get totalHours () {
        let total = 0;
        if(this.distributionEarning._list && !this.distributionEarning.isEmpty()){
            total = round(this.distributionEarning._list.reduce((tot, d) => tot += Number(d['hours']), 0));
        }
        return total;
    }

    /**
     * Is cancel credit: is a credit adjustment (and start credit) with a positive amount (used to reverse a credit)
     */
    get isCancelCredit() {
        return this.type?.config?.isCredit && this.type?.config?.isStartCredit && !this.type?.config?.isCancelUsedCredit && this.total > 0
    }

    get totalDetail() { return sum(this.distributionContribution._list.filter(dist => ['e', 'ev', 'em', 'el', 'es', 'ei'].includes(dist.ta)), 'am') }
    get period() { return this.remittance.period }
    get targetPeriod() { return Period.create(this.endEffDate) }

    /**
     * Is retroactive if the adjustment is targetting a prior year
     * 
     * @returns {boolean}
     */
    get isRetroactive() {  
        const remittanceYear = this.period.yearEndPeriod;
        const adjustmentYear = this.targetPeriod.yearEndPeriod;
        return adjustmentYear.isBefore(remittanceYear) ? true : false;
    }
    
    getByContributionType(type) {return this.distributionContribution._list.find(distribution => distribution.ta === type) ? this.distributionContribution._list.find(distribution => distribution.ta === type).am : 0}

    static refMap = Adjustments
	static key = ['remittance','key', 'category', 'participation']

    static definitions = {
        key: { type: STRING, text: "key"},
        remittance: { key: true, ref: require('../Remittance'), text:'Employee'},
        participation: { key: true, ref: require('../../membership/participation/Participation'), text: 'Participation' },
        type: { ref: require('./AdjustmentType'), text:'Adjustment Type'},
        category: { type: Definition.types.CHOICE, text: 'Adjustment Category', options: [
            { key: 'EARN', text: 'Earning Adjustment' },
            { key: 'CONT', text: 'Contribution Adjustment' },
        ]}, 

        distributionContribution: { map: true, ref: Distribution, text: 'Distribution' },
        distributionEarning: { ref: Earnings, text: 'Distribution Earning'},
        
        effDate: { type: DATE, text: 'Effective Date'},
        endEffDate: { type: DATE, text: 'Effective End Date'},
        retroDate: { type: DATE, text: 'Retroactive Date'},
        cmt: { type: STRING, text: 'Comment'},
        isEEVoluntaryContributionsIgnored: { type: BOOLEAN, text: 'Ignore Employee and Voluntary Contributions' },
        leftOverCredit: { type: AMOUNT },
        
        employment: { transient: true, ref: require('../../employment/Employment'), text: 'Employment' },
        remDetail: { transient: true, ref: require('../RemittanceDetail'), text: 'RemittanceDetail' },

        employerContribution: { abstract: true, type: AMOUNT, text: 'Employer', text_fr: 'Total' },
        solvency: { abstract: true, type: AMOUNT, text: 'Solvency', text_fr: 'Total' },
        interest: { abstract: true, type: AMOUNT, text: 'Interest', text_fr: 'Total' },
        employeeReg: { abstract: true, type: AMOUNT, text: 'Regular Contribs', text_fr: 'Total' },
        employeeVol: { abstract: true, type: AMOUNT, text: 'Voluntary', text_fr: 'Total' },
        employeeMat: { abstract: true, type: AMOUNT, text: 'Materninty', text_fr: 'Total' },
        employeeLtd: { abstract: true, type: AMOUNT, text: 'LTD', text_fr: 'Total' },
        employeeSlf: { abstract: true, type: AMOUNT, text: 'Self', text_fr: 'Total' },
        employeeInterest: { abstract: true, type: AMOUNT, text: 'Employee Interest', text_fr: 'Total' },

        total: { abstract: true, type: AMOUNT, text: 'Total Amount', text_fr: 'Total' },
        totalDetail: { abstract: true, type: AMOUNT, text: 'Total Amount', text_fr: 'Total' },
        totalHours : {abstract: true, type: NUMBER, text: 'Total Hours', text_fr: 'Heure Total'},

        period: { abstract: true, type: PERIOD, text: 'Period' },
        targetPeriod: { abstract: true, type: PERIOD, text: 'Targeted Period', text_fr: 'Période Visé' },

        isRetroactive: { abstract: true, type: BOOLEAN, text: 'Is Retroactive' },
    }
}

