import moment from 'moment';
import { Definition, Entity } from '../../framework/infra'
import { DATE_FORMAT } from '../../framework/utils/helper';
import { Employment } from '../employment';
import Validations from './Validation';
const { AMOUNT, BOOLEAN, NUMBER, STRING, CHOICE, DATE, SIN, YESNO } = Definition.types

//This is necessary for memory issues in reporting. We want to keep the least amount of
//data in memory which is why we pick and choose properties
export default class YearEndEmploymentSummary extends Entity {

    assignValidationResults(validation) {
        this.validation = validation;
    }

    assignLastEnd(detail) {
        this.lastSchedule =  detail.workSchedule;
        this.lastYtdCreditedService =  detail.ytdCreditedService;
        this.lastAnnualizedEarningsRegular = detail.annualizedEarningsRegular;
    }

    assignEnd(detail) {
        this.schedule =  detail.workSchedule;
        this.ytdCreditedService =  detail.ytdCreditedService;
        this.ytdEarnings = detail.ytdEarnings;
        this.ytdContributions = detail.ytdContributions;
        this.ytdRetroContributionsTotal = detail.allYearAdjustment.retro.totalDetail;
        this.pensionAdjustment = detail.pensionAdjustment;
        this.employmentPpStatusDesc = detail.employmentPpStatusDesc;
        this.employmentStatusEffDate = detail.employmentStatusEffDate;
        this.ppStatusEventDesc = detail.ppStatusEvent.eventDesc;
        this.ppStatusEventEffectiveDate = detail.ppStatusEvent.effectiveDate;
        this.annualizedEarningsRegular = detail.annualizedEarningsRegular;
        this.ytdPriorAdjustment = detail.ytdPriorAdjustment;
        this.cmt = detail.cmt;
        this.employerCmt = detail.employerCmt;
        this.financialCmt = detail.financialCmt;
        this.reviewedCmt = detail.reviewedCmt;
        this.reasonDaysDiff = detail.reasonDaysDiff;
        this.confStatus = detail.confStatus;
        this.finalPayOut = detail.finalPayOut;
        this.yeValidated = detail.yeValidated;
        this.detailKey = detail.keyValue;
        this.yearActiveDays = detail.yearActiveDays;
        this.isOnLeaveAndPending = (detail.employmentStatus.isOnLeave() && !detail.employmentStatus.isLeaveException()) || detail.isPending();
        this.lastWorkDay = detail.lastWorkDay  || '';
        this.lastServiceDay = detail.lastServiceDay;
        this.daysDifferential = detail.daysDifferential || 0;
    }

    /**
     * 
     * @param {*} employment The employment
     * @param {*} useDefaultSpouse Flag to get the employment's spouse for setting the marital status.
     * If true, the marital status will be Single if no spouse was set in the employment. If false, the marital status will be blank if no spouse was set in the employment.
     */
    assignEmployment(employment, useDefaultSpouse = true) {
        //employer
        this.code = employment.employer.code;
        this.mercerDiv = employment.employer.mercerDiv;

        //person
        this.sin = employment.person.sin;
        this.lastName = employment.person.lastName;
        this.middleName = employment.person.middleName;
        this.firstName = employment.person.firstName;
        this.dob = employment.person.dob;
        this.name = employment.person.name;
        this.address = employment.person.address?.desc;
        this.email = employment.person.emails?.first?.email;
        this.phone = employment.person.phones?.first?.number;
        this.gender = employment.person.gender;
        this.lng = employment.person.lng;
        this.spouseDob = employment.spouse.dob;
        this.spouseName = employment.spouse.nameFirstLast;
        this.spouseSin = employment.spouse.person.sin;
        this.maritalStatus = useDefaultSpouse ? employment.spouse.maritalStatus : employment.getSpouseNoDefault()?.maritalStatus;

        //employment
        this.noEmp = employment.noEmp;
        this.isCQ = employment.isCQ;
        this.isN = employment.isN;
        this.isTP = employment.isTP;
        this.hiredDate = employment.hiredDate;
        this.employmentType = employment.employmentType;
        this.workSchDesc = employment.workSch.key ? employment.workSch._desc : '';

        //participation
        this.joinDt = employment.participation.joinDt;
        this.beneficiaries = employment.participation.beneficiaries.desc;
        this.mercerKey = employment.participation.mercerKey;
    }

    static definitions = {

        //Person
        code: { type: STRING, text: 'Code' },
        sin: { type: SIN, text: 'SIN' },
        lastName:  { type: STRING, text: 'Last Name' },
        middleName:  { type: STRING, text: 'Middle Name' },
        firstName:  { type: STRING, text: 'First Name' },
        dob: { type: DATE, text: 'Date of Birth' },
        name: { type: STRING, text: 'Name'  },
        address: { type: STRING, text: 'Address' },
        phone: { type: STRING, text: 'Phone' },
        email: { type: STRING, text: 'Email' },
        gender: { type: CHOICE, text: 'Gender' , options: [
            { key: '', text: '', text_fr: ''},
            { key: 'male', text: 'Male' },
            { key: 'female', text: 'Female' },
        ]},
        lng: {
            type: CHOICE, text: 'Language', options: [
            { key: 'en', text: 'English' },
            { key: 'fr', text: 'French' },
        ]},
        spouseName: { type: STRING, text: 'Spouse\'s Name'},
        spouseDob: { type: DATE, text: 'Spouse\'s DOB'},
        spouseSin: { type: SIN, text: 'Spouse\'s SIN'},
        maritalStatus: { type: STRING, text: 'Marital Status'},

        beneficiaries: { type: STRING, text: 'Beneficiaries'},

        //Employment
        noEmp: { type: STRING, text: 'Employee #' }, 
        isCQ: { type: YESNO, text: 'CPP/QPP' },
        isN: { type: YESNO, text: 'Native' },
        isTP: { type: YESNO, text: 'Tax Payer' },
        hiredDate: { type: DATE, text: 'Hired Date'},
        joinDt: { type: DATE, text: 'Join Date' },
        employmentType: { type: CHOICE, text: 'Employment Type', options: [
            { key: 'ft', text: 'Full-Time' },
            { key: 'pt', text: 'Part-Time' },
            { key: 'cs', text: 'Casual'},
        ]}, 
        detailKey: { type: STRING, text: "Remittance Detail Key" },
        workSchDesc: { type: STRING, text: 'Work Schedule' },

        //Detail
        lastSchedule:{ ref: Employment.definitions.workSch.ref, text: 'Yearly Work Schedule' },
        schedule:{ ref: Employment.definitions.workSch.ref, text: 'Yearly Work Schedule' },

        // lastYearlySchedule: { type: NUMBER, text: 'Yearly Work Schedule' },
        // yearlySchedule: { type: NUMBER, text: 'Yearly Work Schedule' },
        lastYtdCreditedService: {type: NUMBER, text: 'YTD Credited Service' },
        ytdCreditedService: { type: NUMBER, text: 'YTD Credited Service' },
        employmentPpStatusDesc: { type: STRING, text: 'Status' },
        employmentStatusEffDate: { type: DATE, text: 'Effective Date' },

        lastWorkDay: { type: DATE, text: 'Last Work Date' },
        lastServiceDay: { type: DATE, text: 'Last Day of Service' },
        daysDifferential: { type: NUMBER, text: 'Days Differential' },

        ppStatusEventDesc: { type: STRING, text: 'Status' },
        ppStatusEventEffectiveDate: { type: DATE, text: 'PP Effective Date' },
        ytdEarnings: { ref: require('../financial/earning/Earnings'), text: 'YTD' },
        pensionAdjustment: { type: AMOUNT, text: 'Pensionable Adjustment' },
        ytdContributions: { ref: require('../pension/contributions/Contributions'), text: 'YTD Contributions' },
        ytdRetroContributionsTotal: { type: AMOUNT, text: 'YTD Retro Contributions' },
        annualizedEarningsRegular: { type: AMOUNT,  text: 'Annualized Earnings Regular'},
        lastAnnualizedEarningsRegular: { type: AMOUNT,  text: 'Annualized Earnings Regular'},
        ytdPriorAdjustment: {type: NUMBER, text: 'YTD adjustment'},        
        cmt: { type: STRING, text: 'Comments'},     
        employerCmt: { type: STRING, text: 'Employer comments'},
        financialCmt: { type: STRING, text: 'Financial Comments'},
        reviewedCmt: { type: STRING, text: 'Admin Validation Comments'},
        reasonDaysDiff: { type: STRING, text: 'Reason for Days Differential'},
        confStatus: { type: STRING, text: 'Confirmation of Status'},
        finalPayOut: { type: STRING, text: 'Final Pay out'},
        yeValidated: { type: BOOLEAN, text: 'Validated'},
        yearActiveDays: {abstract: true, type: Definition.types.NUMBER, text: 'Total Active Days'},

        //Employer
        mercerDiv: { type: STRING, text: 'DIV' },
        mercerKey: { type: STRING, text: 'Mercer Key' },

        //Report
        validation: { ref: Validations, text: 'Validations' },
    }
}
