import { Ref, Definition } from '../../../framework/infra'
import { Period } from '../../../framework/utils'
import { concatStr } from '../../../framework/utils/helper'
import { renderDate } from '../../../framework/utils/renders'

const { AMOUNT, STRING, DATE, PERIOD, CHOICE, BOOLEAN, YESNO} = Definition.types

export default class Payment extends Ref {
    get period() {
        return this.isYearEnd
            ? Period.fromDate(this.yearEndValue)
            : Period.fromDate(this.rcvDate).dec(1);
    }
    get reportDesc() {
        return (
            concatStr(this.no, " | ") +
            concatStr(renderDate(this.rcvDate), " | ") +
            this.amount
        );
    }
    get accepted() {
        return !this.rejected;
    }
    set accepted(val) {
        return (this.rejected = !val);
    }

    isReferenceNumUnique = (remittance, paymentToCheck) =>
        remittance?.payments.all.filter(
            (payment) => payment.no === paymentToCheck.no
        ).length < 1;

    /**
     * Whitelist of the Payment {@link definitions} properties to include in the payload
     * @returns {["no","amount","distribution","period","rcvDate","rejected","cmt","sentDate","rejectionReason","isYearEnd","yearEndValue"]}
     */
    getPayloadInclusions() {
        return [
            "no",
            "amount",
            "distribution",
            "period",
            "rcvDate",
            "rejected",
            "cmt",
            "sentDate",
            "rejectionReason",
            "isYearEnd",
            "yearEndValue",
        ];
    }

    hasChanged(originalPayment) {
        return (
            originalPayment.amount !== this.amount ||
            originalPayment.no !== this.no ||
            originalPayment.rejectionReason !== this.rejectionReason ||
            originalPayment.cmt !== this.cmt ||
            originalPayment.rejected !== this.rejected ||
            originalPayment.accepted !== this.accepted ||
            this.distribution.all.reduce(
                (hasDistributionsChanged, currentDistribution) => {
                    const currentDistributionFound =
                        originalPayment.distribution.all.find(
                            (dist) =>
                                dist.acc === currentDistribution.acc &&
                                dist.period.isSame(currentDistribution.acc) &&
                                dist.amount === currentDistribution.amount
                        );
                    return (
                        hasDistributionsChanged ||
                        !currentDistributionFound ||
                        this.distribution.all.length !==
                            originalPayment.distribution.all.length
                    );
                },
                false
            )
        );
    }

    static definitions = {
        employer: { transient: true, ref: require('../../employment/Employer'), text: "Employer" },
        amount: { type: AMOUNT, text: "Amount", text_fr: "Amount" },
        no: { type: STRING, text: "Reference #", text_fr: "No. de reference" },
        rcvDate: { type: DATE, text: "Received Date", text_fr: "Reçu" },
        sentDate: { type: DATE, text: "Sent To RBC", text_fr: "" },
        period: {
            abstract: true,
            type: PERIOD,
            text: "Assign Remittance Period",
            text_fr: "Reçu",
        },
        isYearEnd: { type: BOOLEAN, text: "Is Year End?" },
        yearEndValue: { type: DATE, text: "Year End Date" },
        distribution: { ref: require('./AccountDistribution'), text: "Distribution" },
        cmt: { type: STRING, text: "Comment" },
        rejected: { type: BOOLEAN, text: "Rejected" },
        rejectionReason: {
            type: CHOICE,
            text: "Rejection Reason",
            default: "",
            options: [
                { key: "", text: "" },
                { key: "i", text: "Insufficient Funds" },
                { key: "d", text: "Data Entry Error" },
                { key: "a", text: "Amount Mismatch" },
            ],
        },
        accepted: { abstract: true, type: BOOLEAN, text: "Accepted" },
    };
}