import { Service } from '../../framework'
import { round } from '../../framework/utils/helper'
import { Period } from '../../framework/utils'

import { Adjustment, AdjustmentType, RemittanceDetail, Distribution, Adjustments, Earnings, Contributions } from '../../entities'
import { SolvencyService, EmploymentService, RemittanceService, RemittanceDetailService, PersonService } from '..'
import { setAPIError } from '../../hooks/useAPIError'
import AdjustmentListMapper from '../../mappers/AdjustmentListMapper'
import { EmploymentBusiness } from '../../business'
import moment from 'moment/moment'
import { adjustmentsTypeConfigs, group } from '../../entities/pension/adjustment/AdjustmentConfigs'

/**
 * @extends {Service}
 */
class AdjustmentService extends Service {
    constructor() { 
        super(Adjustment, 'Adjustment', 'adjustment');
    }

    getAdjustmentsForRemittance(remittance, options) {
        return this.getBy('GetAdjustmentsForRemittance', {remittance: remittance.keyValue}, options, true).then(adjustments => {
            remittance.adjustments = adjustments;
            return adjustments;
        })
    }

    async getAdjustmentsForEmployer(employerId, options = {load: true}) {
        const action = 'GetAdjustmentsForEmployer';
        return await this.callApi(action, action + '_' + employerId, { employer: employerId }, AdjustmentListMapper, options);
    }

    async load(adjustment, options = {}) {
        adjustment.remittance = await RemittanceService.get(adjustment.remittance.keyValue);

        if (adjustment.participation.keyValue) {
            adjustment.employment = await EmploymentService.get(this.getEmploymentId(adjustment));
            adjustment.remDetail = await RemittanceDetailService.get(this.getDetailId(adjustment));
        }

        return adjustment;
    }
    
    getAdjustmentsForEmployerByPeriods(employerId, periods, options) {
        return this.getBy(
            "GetAdjustmentsForEmployerByPeriods",
            { employer: employerId, periods: periods },
            options, true
        );
    }

    getAdjustmentsForMember(person, options){
        return this.getBy('GetAdjustmentsForMember', {person: person.id}, options, true);
    }

    getDetailId(adjustment) {
        return (adjustment.remittance.keyValue + '_' + adjustment.participation.keyValue);
    }
    getEmploymentId(adjustment) {
        return (adjustment.remittance.employer.keyValue + '_' + adjustment.participation.keyValue);
    }

    getAdjustmentsForYear(employerId, year, options){
        return this.getBy('GetAdjustmentsForYear', {employer: employerId, year}, options, true);
    }

    async getAdjustmentsForEmployment(employerId, employment, options){
        let adjustments = await this.persistence.callApi('adjustment_GetAdjustmentsForEmployment', {employer: employerId, employment}, options);
        return this.initList(adjustments, options);
    }

    async initList(adjs, options = {}) {
        let adjustments = new Adjustments();
        for (let adj of adjs) {
            let instAdj = new Adjustment(adj);
            const loadedAdj =  options.load ? await this.load(instAdj) : instAdj;
            adjustments.push(loadedAdj);
        }
        return adjustments;
    }

    getAllAdjustments(options = {}) {
        return this.persistence
            .callApi("adjustment_GetAdjustments")
            .then(async (returnData) => {
                const adjustments = options.load ? new Adjustments() : new Adjustments(returnData);
                if (options.load) {
                    for (let adj of returnData) {
                        await this.load(new Adjustment(adj)).then((a) =>
                            adjustments._list.push(a)
                        );
                    }
                }
                return adjustments;
            })
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });
    }

    link(adjustment) {
        adjustment.distributionEarning.assignEarningTypes(adjustment.employment.employer.earningTypes || adjustment.remittance.employer.earningTypes)
        return adjustment;
    }

    delete(adjustment) {
        this.invalidateCache();
        return this.persistence.delete('adjustment_DeleteAdjustment', adjustment);
    }

    
    //Bussines logic
    createCovid19Adjustments(employers, solvencies, period, comment) {
        if (!period) return []
        return employers.filter(er => SolvencyService.getSolvency(solvencies, er, period)).map(er => {
            return new Adjustment({
                employerId: er.id,
                employer: er,
                period: period,
                effDate: Period.create(period).date,
                type: AdjustmentType.types.C19,
                comment: comment,
                distributionContribution: [{ ta: 's', am: -SolvencyService.getSolvency(solvencies, er, period) }],
            })
        })
    }
    
    createEmployerContributionRateChangeAdjustments(employer, effDate, retroDate, rate, intRate, comment) {
        const effPeriod = Period.fromDate(effDate)
        const retroPeriod = Period.fromDate(retroDate)
        const periodIntFactor = 1 + (intRate / 12)
        const retroactiveRemittances = employer.remittances.filter(rem => rem.period.isSameOrAfter(retroPeriod) && rem.period.isBefore(effPeriod))
        
        var cumulDiff = 0
        var cumulInterest = 0
        var firstOpen = false
        return retroactiveRemittances.reduce((adjustments, rem) => {
            const diff = round(rem.eeAdjustedContribs * (rate - rem.rates.employerContribution))
            const interest = diff > 0 ? round(diff * (Math.pow(periodIntFactor, effPeriod.intValue - rem.period.intValue - 1) - 1)) : 0
            
            if (rem.validated()) {
                cumulDiff += diff
                cumulInterest += interest
            } else {
                const adj = new Adjustment({
                    employerId: employer.id,
                    period: rem.period.value,
                    employer: employer,
                    effDate: effDate,
                    retroDate: retroDate,
                    type: 'ERC',
                    distributionContribution: [{ta: 'r', am: diff}, {ta: 'i', am: interest} ],
                    comment: comment,
                })
                adj.employerCode = employer.code
                
                if (!firstOpen) {
                    firstOpen = true
                    const retroAdj = adj.clone()
                    retroAdj.type = AdjustmentType.types.RCR
                    retroAdj.distributionContribution = [new Distribution({ ta: 'r', am: round(cumulDiff) }), new Distribution({ ta: 'i', am: round(cumulInterest) }) ]
                    retroAdj.employerCode = employer.code
                    adjustments.push(retroAdj)
                }
                adjustments.push(adj)
            }
            return adjustments
        }, [])
    }
    calculateRCR(employer, effPeriod, retroPeriod, rate, intRate) {
        const vals =  employer.remittances.filter(rem => rem.period.isSameOrAfter(retroPeriod) && rem.period.isBefore(effPeriod) && rem.isClose()).reduce((values, rem) => {
            const adjusted = rem.eeAdjustedContribs * rate
            const retro = rem.eeAdjustedContribs * rem.rates.employerContribution
            const diff = round(adjusted - retro)
            const interest = diff > 0 ? round(diff * (Math.pow((1 + (intRate / 12)), effPeriod.intValue - rem.period.intValue - 1) - 1)) : 0

            values.adjusted += adjusted
            values.retro += retro
            values.diff += diff
            values.interest += interest
            return values
        }, { retro: 0, adjusted: 0, diff: 0, interest: 0 })
        Object.getOwnPropertyNames(vals).forEach(valName => vals[valName] = round(vals[valName]))
        return vals
    }

    createSolvencyAmountChangeAdjustments(employer, effDate, retroDate, intRate, comment) {
        const effPeriod = Period.fromDate(effDate)
        const retroPeriod = Period.fromDate(retroDate)
        const currentSolvency = employer.solvencies.getSolvencyAtPeriod(effPeriod)
        const periodIntFactor = 1 + (intRate / 12)
        if (currentSolvency) { 
            const retroactiveRemittances = employer.remittances.filter(rem => rem.period.isSameOrAfter(retroPeriod) && rem.period.isBefore(effPeriod))
            var cumulDiff = 0
            var cumulInterest = 0
            retroactiveRemittances.forEach(rem => {
                const diff = round(currentSolvency.amount - rem.solvency)
                const interest = diff > 0 ? round(diff * (Math.pow(periodIntFactor, effPeriod.intValue - rem.period.intValue - 1) - 1)) : 0
                cumulDiff += diff
                cumulInterest += interest
            })
            if (cumulDiff + cumulInterest) { 
                const adj = new Adjustment({
                    employerId: employer.id,
                    period: effPeriod.value,
                    employer: employer,
                    effDate: effDate,
                    retroDate: retroDate,
                    type: 'RSC',
                    distributionContribution: [{ta: 's', am: cumulDiff}, {ta: 'i', am: cumulInterest} ],
                    comment: comment,
                })
                adj.employerCode = employer.code
                return adj
            }
        }
        return null
    }

    /** Verifies deemed amounts for closed periods of the current year and creates 
     * deemed adjustments if there's missing deemed amounts
     * 
     * @param {*} employment employment to check deemed amounts for
     * @param {{excludeRetroactiveAdjustments: boolean | undefined;} | undefined} options - excludeRetroactiveAdjustments: exclude the retroactive adjustments
     * @returns an object containing deemed earnings/hours/contributions amounts (actual, expected, difference) and 
     *          adjustments created from these amounts (earningAdj and contributionAdj)
     */
    async createDeemedAdjustment(employment, options) {
        const employmentDetails = await RemittanceDetailService.loadDetailsWithAdjustmentsForEmployment(employment, options);
        employmentDetails.setYtps();
        employmentDetails.setYMPEReached();

        const rems = await RemittanceService.getEmployerRemittances(employment.employer.id);
        const prevYear = moment().subtract(1, 'year').format("YYYY");
        const currentYear = moment().format("YYYY");
        const prevYEPeriod = Period.create(moment().subtract(1, 'year').format("YYYY"));
        const previousYERem = rems.find(rem => rem.period.isSame(prevYEPeriod));
        
        return {
            prevYear: !previousYERem.validated ? await this.checkDeemedInYear(employment, prevYear, rems, employmentDetails) : {},
            currentYear: await this.checkDeemedInYear(employment, currentYear, rems, employmentDetails),
        }
    }

    async checkDeemedInYear(employment, year, remittances, employmentDetails) {
        const rems = remittances.getFiltered(rem => rem.period.isSameYear(Period.create(year)));
        const lastValidatedRem = rems.lastValidated;
        const periodForAdj = lastValidatedRem ? (lastValidatedRem.period.moment.month() !== 11 ? new Period(lastValidatedRem.period).inc() : new Period(lastValidatedRem.period.year))  : new Period();
        const firstOpenRem = rems.find(rem => rem.period.isSame(periodForAdj)) || remittances.find(rem => rem.period.isSame(Period.create(moment())));
        const startOfYearPeriod = new Period(moment(year).startOf("year").valueOf());
        let earningAdj, contributionAdj;
        
        if (lastValidatedRem?.period.isSameYear(startOfYearPeriod)) { 
            const {
                actual,
                expected,
                difference,
                calculatedDetails,
            } = await EmploymentBusiness.checkDeemedAmounts({
                employment, 
                startPeriod: startOfYearPeriod,
                endPeriod: lastValidatedRem?.period,
                dbDetails: employmentDetails,
            });
    
            if (difference.contributions.length !== 0) {
                const rate = employment.employer.plan.historicRates.getRatesAtPeriod(periodForAdj).employerContribution;
                let distribution = [];
                if (difference.contributions.ltd) distribution = [...distribution, new Distribution({ ta: 'el', am: difference.contributions.ltd })];
                if (difference.contributions.mat) distribution = [...distribution, new Distribution({ ta: 'em', am: difference.contributions.mat })];
                if (difference.contributions.slf) distribution = [...distribution, new Distribution({ ta: 'es', am: difference.contributions.slf })];
                if (difference.contributions.total !== 0) distribution = [...distribution, new Distribution({ ta: 'r', am: round(difference.contributions.total * rate), autoEEContrib: true})];
                contributionAdj = new Adjustment({
                    employer: employment.employer,
                    remittance: firstOpenRem.keyValue,
                    participation: employment.participation.keyValue,
                    type: AdjustmentType.types.DEEM,
                    category: group.CONT,
                    distributionContribution: distribution,
                    effDate: moment(year, "YYYY").startOf("year").format("YYYY-MM-DD"),
                    endEffDate: lastValidatedRem.period.moment.format("YYYY-MM-DD"),
                });
            }
    
            if (difference.earnings.length !== 0) {
                earningAdj = new Adjustment({
                    employer: employment.employer,
                    remittance: firstOpenRem.keyValue,
                    participation: employment.participation.keyValue,
                    type: AdjustmentType.types.DEEM,
                    category: group.EARN,
                    distributionEarning: difference.earnings,
                    effDate: moment(year, "YYYY").startOf("year").format("YYYY-MM-DD"),
                    endEffDate: lastValidatedRem.period.moment.format("YYYY-MM-DD"),
                });
            }
            return { actual, expected, difference, earningAdj, contributionAdj }
        } else { 
            return {
                difference: {
                    earnings: new Earnings(),
                    contributions: new Contributions(),
                }
            }
        }
    }

    /** Applies all adjustments for an employment in effective period to input remittance detail
     * 
     * @param {*} detail the remittance detail to apply the adjustments to
     * @returns the remittance detail with the adjustments applied
     */
    async applyAdjustmentsInEffectivePeriod(detail, startPeriod, endPeriod) {
        const adjustments = await this.getAdjustmentsForEmployment(detail.employment.employer.id, detail.employment.keysValues.participation);
        const adjustmentsInEffectivePeriod = adjustments._list.filter(adj => {
            const adjEffStartPeriod = new Period(adj.effDate);
            const adjEffEndPeriod = new Period(adj.endEffDate);
            const effDtBeforeEndPeriod = adjEffStartPeriod.isSameOrBefore(endPeriod);
            const effEndDtBeforeStartPeriod = adjEffEndPeriod.isSameOrAfter(startPeriod);

            return effDtBeforeEndPeriod && effEndDtBeforeStartPeriod && (adj.period.isAfter(endPeriod) && adj.period.isSameYear(endPeriod));
        });

        adjustmentsInEffectivePeriod.forEach(adj => {
            if (adj.category === group.CONT) detail.adjustmentContributions.addAjdContrib(adj);
            if (adj.category === group.EARN) detail.adjustmentEarnings.add(adj.distributionEarning.all)
        })

        return detail;
    }

    /**
     * Get start or used credit (or reversing credit, if included) ajs for each adj type group
     * 
     in the same or before periods
     * @param {*} period 
     * @param {*} adjustments 
     * @param {*} includeReversing 
     * @returns an array of 0 or 1 adj for each group of credit adj
     */
    getMissingStartCreditsForAllTypes(period, adjustments, includeReversing = true) {
        let missingStartCredits = [];
        const groupedTypes = AdjustmentType.getCreditTypes()
            .filter(key => adjustmentsTypeConfigs[key]?.isCredit && adjustmentsTypeConfigs[key]?.isStartCredit)
            .map(key => new AdjustmentType(key));

        for(const type of groupedTypes) {
            const prevCredits = adjustments.filter(adj => adj.period.isSameOrBefore(period));
            const startCredits = prevCredits.filter(adj => adj.type.key === type.key && adj.leftOverCredit !== 0);
            const usedCredits = prevCredits.filter(adj => adj.type.key === `${type.key}R`);
            // including the reversing credits is also done in src/business/RemittanceBusiness.js in applyAllCredit()
            /** Reversing credits: adjustments of type MECH or RCR or SOC with positive amount, that will reduce or cancel the credit available */
            const startCancelCredits = prevCredits.filter(adj => adj.type.key === type.key && adj.isCancelCredit && !adj.period.isBefore(Period.getTotalOwingStartPeriod()));
            const startCancelUsedCredits = prevCredits.filter(adj => adj.type.key === type.key && adj.type.config.isCancelUsedCredit && !adj.period.isBefore(Period.getTotalOwingStartPeriod()));
            const sortedCredits = new Adjustments([...startCredits, ...usedCredits, ...(includeReversing ? [...startCancelCredits, ...startCancelUsedCredits] : [])]).sortNewestToOldest();
            const newestAdjOfType = sortedCredits.last;

            if (newestAdjOfType && newestAdjOfType.period.isBefore(period)) missingStartCredits.push(newestAdjOfType);
        } 
        return missingStartCredits;
    }

    /**
     * Get "cancel/reversing credit" adjustments (from previous periods excluding the provided period)
     * @param {*} period The period
     * @param {*} adjustments The adjustments
    * @returns an array of 0 or 1 adj for each group of the "reversing credits" adjustment types (start credits MECH/RCR adjustments with a positive amount) from before the period.
     */
    getMissingReversingCreditsForAllTypes(period, adjustments) {
        let missingReversingCredits = [];
        const groupedTypes = AdjustmentType.getCreditTypes()
            .filter(key => adjustmentsTypeConfigs[key]?.isCredit && adjustmentsTypeConfigs[key]?.isStartCredit)
            .map(key => new AdjustmentType(key));

        for(const type of groupedTypes) {
            const prevCredits = adjustments.filter(adj => adj.period.isBefore(period));
            // including the reversing credits is also done in src/business/RemittanceBusiness.js in applyAllCredit()
            /** Reversing credits: adjustments of type MECH or RCR or SOC with positive amount, that will reduce or cancel the credit available */
            const startCancelCredits = prevCredits.filter(adj => adj.type.key === type.key && adj.isCancelCredit && !adj.period.isBefore(Period.getTotalOwingStartPeriod()));
            const sortedCredits = new Adjustments([...startCancelCredits]).sortNewestToOldest();
            const newestAdjOfType = sortedCredits.last;
            
            if (newestAdjOfType && newestAdjOfType.period.isBefore(period)) missingReversingCredits.push(newestAdjOfType);
        } 

        return missingReversingCredits;
    }

    /**
     * Get "cancel/reversing credit" adjustments (from previous periods excluding the provided period)
     * @param {*} period The period
     * @param {*} adjustments The adjustments
    * @returns an array of 0 or 1 adj for each group of the "reversing used credits" adjustment types (MECHC/RCRC) from before the period.
     */
    getMissingReversingUsedCreditsForAllTypes(period, adjustments) {
        let missingReversingUsedCredits = [];
        const groupedTypes = AdjustmentType.getCreditTypes()
            .filter(key => adjustmentsTypeConfigs[key]?.isCancelUsedCredit)
            .map(key => new AdjustmentType(key));

        for(const type of groupedTypes) {
            const prevCredits = adjustments.filter(adj => adj.period.isBefore(period));
            const cancelUsedCredits = prevCredits.filter(adj => adj.type.key === type.key /* && adj.isCancelCredit */ && !adj.period.isBefore(Period.getTotalOwingStartPeriod()));
            const sortedCredits = new Adjustments([...cancelUsedCredits]).sortNewestToOldest();
            const newestAdjOfType = sortedCredits.last;
            if (newestAdjOfType && newestAdjOfType.period.isBefore(period)) missingReversingUsedCredits.push(newestAdjOfType);
        } 

        return missingReversingUsedCredits;
    }
}


const instance = new AdjustmentService()
export default instance
