import _ from 'lodash'
import moment from 'moment'
import util from 'util'
import deepFreeze from 'deep-freeze'
import formatStringByPattern from  'format-string-by-pattern'
import stringSimilarity from 'string-similarity'
import config from '../../utils/config'
require('json-circular-stringify')

function appCmt(cmt = '') { return cmt + ' [APP]' }
function isString(str) { return typeof str === 'string' || str instanceof String }
function isEmpty(str) { return str == null || str === '' }
function isValueUndefined(value) {
    return (value === undefined || isEmpty(value));
  }
function isEmptyString(str) { return str === '' }
function concatStr(str, sep = ' ') { return str ? (str.trim() + sep) : '' }
function mask(str, pattern ='') { return formatStringByPattern(pattern, str) }
function pad(str, length, padChar) {  return str } //TODO

//converters
function toLower(str) { return isString(str) ? str.toLowerCase() : str }
function toUpper(str) { return isString(str) ? str.toUpperCase() : str }
function wordToPascal(str = '') {
    var res = String(str)
    return res.length > 0 ? (res[0].toUpperCase() + res.substring(1).toLowerCase()) : res 
}
function phraseToPascal(str) {
    if (!str) return ''
    str = toLower(String(str))
    var lastIndex = 0
    var res = ''
    str.replace(/[\s|','|.|-]+/g, function(match, index, original)  {
        var word = original.substring(lastIndex, index)
        res += wordToPascal(word) + match
        lastIndex = index + match.length
    })
    res += wordToPascal(str.substring(lastIndex))
    return res
}
function replaceParams(text, params) {
    if (!text) return ''
    if (!params) return text
    Object.getOwnPropertyNames(params).forEach(paramName => {
        text = text.replace(new RegExp(`{if:${paramName}}(.*){fi:${paramName}}`, 'g'), params[paramName] ? '$1' : '')
        text = text.replace(new RegExp(`{${paramName}}`, 'g'), params[paramName]) 
    })
}

/**
 * Format a string for comparison.
 * @param {string} str The string
 * @returns The string as lowercase, keeping only the alphanum characters
 */
function toSearchString(str) { return String(str).replace(/[^a-zA-Z0-9|&]+/g, '').toLowerCase() }
function compareTwoStrings(str1, str2) { return stringSimilarity.compareTwoStrings(str1, str2) }

/**
 * Compare 2 string, case insensitive and ignore special characters (keep only alphanum characters)
 * @param {string} s1 The first string
 * @param {string} s2 The second string
 * @returns true if the strings are the same, returns false otherwise
 */
function isSameAlphaNumStrings(s1, s2) {
    return toSearchString(s1) === toSearchString(s2);
}

//Object
function isObject(val) { return val instanceof Object && !isArray(val) }
function isEmptyObj(obj){ return isObject(obj) && Object.getOwnPropertyNames(obj).length === 0 }
function objHasNoValue(obj) { return !isObject(obj) || !Object.values(obj).find(v => v !== null && v !== undefined) }
function objHasValue(obj) { return !objHasNoValue(obj) }
function toJson(obj, replacer, spaces) { return JSON.stringify(obj, replacer) }
function objToArray(obj, keyPropName) {
    return Object.getOwnPropertyNames(obj).map(key => {
        if (keyPropName) obj[key][keyPropName] = key
        return obj[key]
    })
}
function objFilter(obj, remover) {
    return Object.getOwnPropertyNames(obj).forEach(prop => {
        if (remover(obj[prop])) delete obj[prop]
    })
}
function getSafe(obj, path) {
    if (!path || path.length === 0) return obj
    if (!Array.isArray(path)) path = path.split('.')
    var prop = path.splice(0,1)
    var value = obj && obj[prop[0]]
    return value && getSafe(value, path)
}

function setSafe(obj, path, value) {
    if (!obj || !path) return value
    if (!Array.isArray(path)) path = path.split('.')
    if (path.length === 1) return obj[path[0]] = value
    else return setSafe(obj[path[0]], path.slice(1), value)
}

function removeFalsy(obj) { return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {}) }

//Array
function isArray(val) { return val instanceof Array }
//TODO rename/crate/modify 'arrayXxxxx' series to contains only functions that modifies the array paramenter
function array2Object(array, prop) { return map(array, prop) }
function map(array, prop) {
    if (!prop || !array) return {}
    return array.reduce((map, obj) => {
        const value = _.get(obj, prop)
        if (value) map[value] = obj
        return map
    }, {})
}
function group(array, prop) {
    if (!prop || !array) return {}
    return array.reduce((map, obj) => {
        const value = _.get(obj, prop)
        if (value) {
            map[value] = map[value] || []
            map[value].push(obj)
        }
        return map
    }, {})
}
function duplicates(array, prop) {
    return Object.values(group(array, prop)).reduce((dups, gr) => {
        if(gr.length > 1) dups[_.get(gr[0], prop)] = gr
        return dups
    }, {})
}
function removeDuplicates(array, prop) {
    return Object.values(map(array, prop))
}
//Accepts an array and a csv string (removes spaces)
function arrayFromCsv(val = []) { return [].concat(val).join(',').split(' ').join('').split(',') }
function arrayPropSort(array, prop) { return sort(array, prop) }
function sort(array, prop) {
    return [...array].sort((a, b) => {
        const valA = _.get(a, prop)
        const valB = _.get(b, prop)
        if (valA === valB) return 0
        return valA > valB ? 1 : -1
    })
}

//deprecated - use arrayFilter
function arrayRemove(array, remover) { return arrayFilter(array, remover) }
function arrayFilter(array, remover) { return _.remove(array, remover) }
function pick(array, props) { return _.pick(array, props) }
function omit(array, props) { return _.omit(array, props)}
function arrayPushNonNull(array, val) { if(isArray(array) && val !== null && val !== undefined) array.push(val); return val }
function insertAt(array, element, index = 0) { array = array.splice(0, index).concat(element, array) } // returns new array

//Math
function isNumeric(val) { return !isNaN(parseFloat(val)) && isFinite(val) }
function round(value = 0, decimals = 2) { return Number(((Number(value) || 0) + 0.00000000001).toFixed(decimals)) }
function sum(list, prop, decimals) {
    if (!list || !prop) return 0
    return round(list.reduce((sum, obj) => { 
        var parsed = obj[prop] ? Number(obj[prop]) : 0
        if(isNaN(parsed)) parsed = 0;
        return sum += parsed;
    }, 0), decimals)
}
const add = (a,b) => {
    let array = [{amount: a}, {amount: b}]
    return sum(array, 'amount')
}


//Date
const DATE_FORMAT = 'YYYY-MM-DD';
const LONG_FORMAT = 'MMMM D, YYYY';
const ETS_FORMAT = 'DD MMM YYYY';

function isValidDate(date = '') {
    return moment(date, DATE_FORMAT, true).isValid()
}
function toDBDate(date) {
    if (!date) return moment().format(DATE_FORMAT)
    else return moment(date).format(DATE_FORMAT)
}
function toEpochTs(date) {
    if (!date) return;
    return moment(date, DATE_FORMAT).valueOf();
}
function zeroForInfinity(num) { return  isFinite(num) ? num : 0 }
function isDateSameOrAfter(yyyymmdd) { return moment(yyyymmdd, DATE_FORMAT).isSameOrAfter(moment(), 'date') }
function today() { return moment().format(DATE_FORMAT) }
function toTimestamp(val) {
        if (!val) val = Date.now()
        else if (val instanceof Date || val instanceof moment) val = val.valueOf()
        else if (typeof val === 'string') val = moment(val).valueOf()
        return val
}
function parseExcelDate(val) {
	if (!val) return '';
	return val && !isNaN(val) ? new Date(Math.round((Number(val) - 25569)*86400*1000)).toISOString().slice(0, 10) : '';
}
function format(...args) { return util.format(...args) }

//Boolean
function isFalsy(val) { return val && val !== 'false' && val !== 'n' ? false : true }
function toBool(val) {
    const value = String(val).toLowerCase()
    return value.startsWith('y') || value === 'true'
}

function isValidSIN(val) {
    let check, sin;
    sin = val;
    if (typeof sin === 'number') {
        sin = sin.toString();
    }
    
    if (sin.length === 9 && sin !== '000000000') {
        // convert to an array & pop off the check digit
        sin = sin.split('');
        check = +sin.pop();

        // take the digits at the even indices
        // multiply them by two
        // and split them into individual digits
        const evenDigits = sin.filter((_, i) => i % 2).map((n) => n * 2).join('').split('');

        // take the digits at the odd indices
        // concatenate them with the transformed numbers above
        // it's currently an array of strings; we want numbers
        // and take the sum
        const total = sin.filter((_, i) => !(i % 2)).concat(evenDigits).map((n) => Number(n)).reduce((acc, cur) => acc + cur, 0);
    
        // compare the result against the check digit
        return check === (10 - (total % 10)) % 10;
    } 

    return false;
}

function cleanseSIN(sin) {
    return String(sin).trim().split('-').join('').split(' ').join('');
}

function formatSIN(sin) {
    try {
        const stringSin = String(sin).trim();
    return `${stringSin.substring(0, 3)}-${stringSin.substring(3, 6)}-${stringSin.substring(6, 9)}`;
    } catch(err){}
    return sin;
}

function isEmailValid(val){
    return val.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
}

function replaceElementDesc(desc, instance){
    return desc.replace(/{(.*?)}/g, (match, property) => { return _.get(instance, property) || match});
}

function safeEmail(emails = []) {
    let uniqueEmails = Array.from(new Set(emails.filter(item => item !== undefined)));
    if (config.debug) {
        //avoid people sending out emails to actual users in 
        uniqueEmails = uniqueEmails.map(x=> 'TEST-ENV-' +x);
    }
    return uniqueEmails;
}

function removeNonNumeric(str) {
    // Use the replace function with a regular expression to keep only numbers
    return str.replace(/\D/g, '');
}

export {
    moment,
    deepFreeze,
    isDateSameOrAfter,
    isObject,
    isString,
    isNumeric,
    round,
    sum,
    zeroForInfinity,
    toLower,
    toUpper,
    toBool,
    toDBDate,
    wordToPascal,
    phraseToPascal,
    replaceParams,
    compareTwoStrings,
    isEmpty,
    isEmptyString,
    objToArray,
    isEmptyObj,
    arrayPushNonNull,
    concatStr,
    mask,
    pad,
    getSafe,
    setSafe,
    array2Object,
    arrayRemove,
    objFilter,
    objHasNoValue,
    objHasValue,
    arrayFilter,
    arrayPropSort,
    arrayFromCsv,
    insertAt,
    group,
    duplicates,
    map,
    sort,
    pick,
    today,
    toTimestamp,
    omit,
    removeFalsy,
    isArray,
    isFalsy,
    isValidSIN,
    cleanseSIN,
    formatSIN,
    toSearchString,
    isSameAlphaNumStrings,
    parseExcelDate,
    format,
    toJson,
    removeDuplicates,
    isValidDate,
    toEpochTs,
    isEmailValid,
    DATE_FORMAT,
    LONG_FORMAT,
    ETS_FORMAT,
    replaceElementDesc,
    add,
    safeEmail,
    appCmt,
    isValueUndefined,
    removeNonNumeric,
}
