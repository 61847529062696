
import { pick, isArray } from './helper'

/**
 * Recursively (*partially recursive) check on all request payload properties for a payload simplification option.
 * 
 * @remarks The payload simplification is only applied on enumerable keys (not inherited) and on the first level and direct children properties that have a payload simplification option.
 * @param {*} data 
 * @returns The data with only the included payload (or all data from enumerable properties if no payload inclusions is configured)
 */
function simplifyPayload(data) {
    var instance = data.getPayloadInclusions ? pick(data, [...data.getPayloadInclusions()]) : data;
    Object.keys(instance).forEach((key) => {
        if(isArray(instance[key])){
            var newObjs = []
            for (var arrayObj of instance[key]) {
                newObjs.push(arrayObj.getPayloadInclusions ? simplifyPayload(arrayObj) : arrayObj);
            }
            instance[key] = newObjs;
        }else {
            instance[key] = instance[key]?.getPayloadInclusions ? simplifyPayload(instance[key]) : instance[key];
        }
    });
    return instance;
}

const canHavePayloadInclusions = (data) => {
    return (typeof data === "object" || Array.isArray(data)) && data !== null; // typeof null = 'object'
}

/**
 * Simplify the payload of an array
 * @param {[]any} dataArray 
 * @returns {any | any[]} The array with each item simplified, or the data if it was not an array
 */
const simplifyFullPayloadArray = (dataArray) => {
    if(!isArray(dataArray)){
        return dataArray;
    }
    var newObjs = []
    for (var arrayObj of dataArray) {
        newObjs.push(canHavePayloadInclusions(arrayObj) ? simplifyFullPayload(arrayObj) : arrayObj);
    }
    return newObjs;
}

/**
 * Recursively check on all request payload properties for a payload simplification option.
 * 
 * @remarks The payload simplification is only applied on the provided data and on its enumerable keys (not inherited).
 * @param {object | null | undefined} data 
 * @returns The data with only the included payload (or all data if no payload inclusions is configured)
 */
function simplifyFullPayload(data) {
    if(!canHavePayloadInclusions(data)) return data;
    if(Array.isArray(data)) return simplifyFullPayloadArray(data);
    else if(typeof data === 'object' && data !== null){
        var instance = data.getPayloadInclusions ? pick(data, [...data.getPayloadInclusions()]) : data;
        Object.keys(instance).forEach((key) => {
            if(isArray(instance[key])){
                var newObjs = simplifyFullPayloadArray(instance[key]);
                try{
                    instance[key] = newObjs;
                }
                catch(err){
                    if(err instanceof TypeError){
                        // for example when assigning a key that is a getter:
                        // Cannot assign to read only property 'cmt' of object
                        console.warn(err);
                        console.debug('Error in simplifyFullPayload, cannot set key with value', key, newObjs);
                    } else throw err;
                }
            } else {
                const simplifiedPayload = canHavePayloadInclusions(instance[key]) ? simplifyFullPayload(instance[key]) : instance[key];
                try{
                    instance[key] = simplifiedPayload;
                }
                catch(err){
                    if(err instanceof TypeError){
                        // for example when assigning a key that is a getter:
                        // Cannot assign to read only property 'cmt' of object
                        console.warn(err);
                        console.debug('Error in simplifyFullPayload, cannot set key with value', key, simplifiedPayload);
                    } else throw err;
                }
            }
        });
        return instance;
    }
    else return data;
}

export {
    simplifyPayload,
    simplifyFullPayload
}
