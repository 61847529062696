import { Service } from '../../framework'
import { BeneficiaryService, EmployerService, PersonService } from '..';
import PersonalInformationResults from '../../entities/search/PersonalInformationSearch/PersonalInformationResults';

class PersonalInformationSearch extends Service {
    constructor() {
        super(PersonalInformationResults, 'Employments', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }

    async load(eventResults, options) {
        const documents = eventResults.documents.all || [];
        for (const document of documents) {
            document.employer = await EmployerService.get(document.employerId);;
            document.person = await PersonService.get(document.personId);
            document.beneficiaries = await BeneficiaryService.get(document.personId);
        }
        return eventResults
    }
}

const instance = new PersonalInformationSearch()
export default instance

