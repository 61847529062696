import RefDated from './RefDated'
import Ref from './Ref'
import RefDescription from './RefDescription'
import Definition from './Definition'
import RefMap from './RefMap'
import { deepFreeze } from '../../utils/helper'
import RefEnum from './RefEnum'

class Param extends Ref {
    static definitions = {
        key: { type: Definition.types.STRING },
        value: { type: Definition.types.STRING }
    }
    static key = 'key'
}
class Params extends RefMap { static ref = Param }

export default class RefMessage extends RefDated {
    static key = 'code'
    static definitions = {
        code: { type: Definition.types.STRING, text: 'Message Code' },
        cmt: { type: Definition.types.STRING, text: 'Comment'},
        reviewed: { type: Definition.types.BOOLEAN, text: 'Reviewed' },
        params: { ref: Params, text: 'Parameters', text_fr: 'Parameters' },

        name: { abstract: true, type: Definition.types.STRING, text: 'Message Name' },
        key: { absrtact: true, type: Definition.types.STRING, text: 'Key' },
        severity: { abstract: true, type: Definition.types.STRING, text: 'Severity' },
        desc: { abstract: true, type: Definition.types.STRING, text: 'Message' },
        title: { abstract: true, type: Definition.types.STRING, text: 'Message' },
    }
    static isMessage = true


    get code() { return this.__code }
    set code(code) {
        this.__code = code
        this._msgDesc = this.constructor.messages[code] ||  new RefDescription()
    }

    set severity(severity) { this._msgDesc = new RefDescription({ 
        key: this._msgDesc.key, 
        text: this._msgDesc.text, 
        name: this._msgDesc.name, 
        title: this._msgDesc.title, 
        severity: severity, 
        options: this._msgDesc.options,
    }) }

    get severity() { return this._msgDesc.severity }
    get desc() { return this._msgDesc.getDesc(this.params) }
    get name() { return this._msgDesc.name }
    get title() { return this._msgDesc.title || this.desc }
    get options() { return this._msgDesc.options || {} }
    
    isError(){ return this.severity === 'e'}
    isWarning(){ return this.severity === 'w' }
    getSeverityPonderation() {return  (this.severity && { i: 1,w: 2,e: 3 }[this.severity]) || 0 }
    isMoreSevere(messages) { return this.getSeverityPonderation() > messages.getSeverityPonderation() }


    
    static get messages() { return this._messages || {} }
    static set messages(messageList) {
        this._messages = deepFreeze(messageList.reduce((types, msg) => { 
            if(!(msg instanceof RefDescription)) {
                msg = new RefDescription({key: msg[0], text: msg[1], name: msg[2], title: msg[2], severity: msg[3], options: msg[4]})
            }
            this[msg.key] = types[msg.key] = msg
            return types 
        }, {}))
    }
    static get groupedMessages() {
        return Object.values(this.messages).reduce((eventGroups, message) => {
            if (message.options.groups) {
                message.options.groups.forEach(groupName => {
                    eventGroups[groupName] = eventGroups[groupName] || []
                    eventGroups[groupName].push(message)
                })
            } 
            return eventGroups
        }, {})
    }

    static sort(a, b) {
        if (!a || !b) {
            if (!a && !b) return 0
            return !a ? 1 : -1
        }
        if (!a.desc || !b.desc) {
            if (!a.desc && !b.desc) return 0
            return !a.desc ? 1 : -1
        }
        
        if (a.getSeverityPonderation() === b.getSeverityPonderation()) {
            if (a.desc === b.desc) return 0
            return a.desc > b.desc ? 1 : -1
        }
        return b.getSeverityPonderation() - a.getSeverityPonderation()
    }
   
}

//TODO to continue...
class MessageSeverity extends RefEnum {
    static types = deepFreeze({
        i: new MessageSeverity('i', 'Info'),
        w: new MessageSeverity('w', 'Warning'),
        e: new MessageSeverity('e', 'Error'),
    })
    
    static definitions = {
        ponderation: { abstract: true, type: Definition.types.NUMBER, text: 'Severity ponderation' },
    }

    get ponderation() {
        return  { i: 1,w: 2,e: 3 }[this.key]
    }

    isError(){ return this.key === 'e' }
    isWarning(){ return this.key === 'w' }

}