import StringInput from '../../../components/filters/StringInput';
import { Definition }  from '../../../framework/infra';
import RefFilter from '../../../framework/infra/model/RefFilter';
import { ETS_FORMAT } from '../../../framework/utils/helper';
import { employmentEventConfigs } from '../../employment/EmploymentConfig';
import { ParticipationEvent } from '../../membership';
import { Beneficiaries } from '../../pension';
import { Person, Spouse } from '../../person';
import EventView from '../EventSearch/EventView';
import moment from 'moment';

export default class PersonalInformationView extends EventView {

    get address() { return this.person.address.desc; }
    get email() { return this.person.emails.desc; }
    get phone() { return this.person.phones.desc; }
    get spouse() { return this.person.findSpouse(this.employer.jurisdictionCode); }

    get hiredDate() {
        const hiredEvent = this.employmentEvents.find(e => employmentEventConfigs[e.code].isHiredEvent);
        return hiredEvent ? moment(hiredEvent.effectiveDate).format(ETS_FORMAT): "";
    }

    /**
     * Get the last participation status event
     * @returns {ParticipationEvent} the last participation status event
     */
    get lastParticipationStatus() {
        const lastPPEvent = this.participationEvents[this.participationEvents.length - 1];
        return this.participationEvents.length > 0 
            ? new ParticipationEvent({code: lastPPEvent.code, ets: lastPPEvent.effectiveDate})
            : undefined;
    }

    /**
     * Get the status description of the last participation event with a status change
     * @returns {string} description of the last participation status
     */
    get lastParticipationStatusDesc() {
        return this.lastParticipationStatus?.desc ?? "";
    }

    /**
     * Get the effective date of the last participation status.
     * @returns {string} the effective date of the last participation status
     */
    get lastParticipationStatusEffectiveDate() {
        return this.lastParticipationStatus ? moment(this.lastParticipationStatus.effectiveDate).format(ETS_FORMAT) : "";
    }

    /**
     * Get the date of birth of the person as a string
     * @returns {string} the date of birth of the person as a string with ETS format (DD MMM YYYY)
     */
    get dateOfBirthString() {
        return this.dateOfBirth ? moment(this.dateOfBirth).format(ETS_FORMAT) : "";
    }

    /**
     * Get the date of birth of the spouse as a string
     * @returns {string} the date of birth of the spouse as a string with ETS format (DD MMM YYYY)
     */
    get spouseDob() {
        return this.spouse?.dob ? moment(this.spouse.dob).format(ETS_FORMAT) : "";
    }

    /**
     * Get the config object for the personal information export file
     * filename: the name of the export file
     * sheetName: the name of the sheet in the export file
     * columns: the columns to export
     * headerTitles: the titles of the headers to be changed in the export file
     * @returns {object} the export configuration object 
     */
    static get exportConfigs() {
        return {
            fileName: 'PersonalInformationExport-' + new Date().toISOString().slice(0, 10),
            sheetName: 'Personal Information',
            columns: ['sin', 'mercerKey', 'employer.code', 'employeeNumber', 'person.firstName', 'person.middleName', 'person.lastName', 'dateOfBirthString', 'person.gender', 'person.lng', 'address', 'email', 'phone', 'isNative', 'cqpp', 'isTaxPayer', 'hiredDate', 'joinDateString', 'lastParticipationStatusDesc', 'lastParticipationStatusEffectiveDate', 'spouse.maritalStatus', 'spouse.nameFirstLast', 'spouse.sin', 'spouseDob', 'spouse.taxStatus', 'beneficiaries.desc'],
            headerTitles: {
                'employer.code': 'ER Code',
                'person.gender': 'Gender',
                'spouse.maritalStatus': 'Marital Status',
                'spouse.nameFirstLast': 'Spouse Name',
                'spouse.sin': 'Spouse SIN',
                'spouse.taxStatus': 'Spouse Tax Status',
            },
        };
    }

    /**
     * Get the filters for the personal information report
     * @returns {PersonalInformationViewFilters} the filters for the personal information report
     */
    static get filters() {
        return PersonalInformationViewFilters;
    }

    static definitions = {
        mercerKey: { type: Definition.types.STRING, text: 'Mercer Key' },
        isNative: { type: Definition.types.YESNO, text: 'Native' },
        isTaxPayer: { type: Definition.types.YESNO, text: 'Tax Payer' },

        dateOfBirthString: { abstract: true, type: Definition.types.STRING, text: 'Date of Birth' },
        hiredDate: { abstract: true, type: Definition.types.STRING, text: 'Hired Date'},
        address: { abstract: true, type: Definition.types.STRING, text: 'Address' },
        email: { abstract: true, type: Definition.types.STRING, text: 'Email' },
        phone: { abstract: true, type: Definition.types.STRING, text: 'Phone' },
        lastParticipationStatus: { abstract: true, ref: ParticipationEvent, text: 'Last PP Status' },
        lastParticipationStatusDesc: { abstract: true, type: Definition.types.STRING, text: 'Last PP Status' },
        lastParticipationStatusEffectiveDate: { abstract: true, type: Definition.types.STRING, text: 'Last PP Status Effective Date' },
        spouse: { abstract: true, type: Spouse, text: 'Spouse' },
        spouseDob: { abstract: true, type: Definition.types.STRING, text: 'Spouse Date of Birth' },
        filters: { abstract: true, type: PersonalInformationViewFilters, text: 'Filters' },

        //transients
        person: { transient: true, ref: Person, text: 'Person' }, 
        beneficiaries: { transient: true, type: Beneficiaries, text: 'Beneficiaries' },
    }
}

class PersonalInformationViewFilters extends RefFilter {

    constructor(data) {
        super(data, PersonalInformationViewFilters.definitions);
    }

    static definitions = {
        ...EventView.filters.definitions,
        isNative: { 
            isFilter: true,
            type: Definition.types.CHOICE, 
            text: 'Native', 
            icon: 'id-badge',
            limit: 1,
            component: <StringInput options={
                [
                    { key: 'true', value:'true', text: 'Yes', },
                    { key: 'false', value:'false', text: 'No', },
                ]
            } />,
            getLabel: (instance) => { 
                return instance.isNative === 'true' ? 'Is Native' : 'Not Native';
            },
        },
        isTaxPayer: { 
            isFilter: true,
            type: Definition.types.CHOICE, 
            text: 'Tax Payer', 
            icon: 'money-bill-alt',
            limit: 1,
            component: <StringInput options={
                [
                    { key: 'true', value:'true', text: 'Yes', },
                    { key: 'false', value:'false', text: 'No', },
                ]
            } />,
            getLabel: (instance) => { 
                return instance.isTaxPayer === 'true' ? 'Is Tax Payer' : 'Not Tax Payer';
            },
        },
    }
}