import { AdjustmentService, BeneficiaryService, EmployerService, EmploymentService, MembershipService, PersonService, RemittanceDetailService, RemittanceService, SpouseService } from "../services";

/**
 * Supported entities: beneficiaries, spouses, remittances, people, memberships, employments, details, adjustments, employers
 */
export default class Mapper {
    
    constructor (data) {
        this.data = data;
        
        //register entity services here, or custom ones
        this.entities = {
            beneficiaries:  BeneficiaryService,
            spouses:        SpouseService,
            remittances:    RemittanceService,
            people:         PersonService,
            memberships:    MembershipService,
            employments:    EmploymentService,
            details:        RemittanceDetailService,
            adjustments:    AdjustmentService,
            employers:      EmployerService,
        }
    }

    /**
     * @type {{beneficiaries: BeneficiaryService; spouses: SpouseService; remittances: RemittanceService; 
     * people: PersonService; memberships: MembershipService; employments: EmploymentService; details: RemittanceDetailService; adjustments: AdjustmentService; employers: EmployerService;}}
     */
    entities;

    /**
     * @type {{beneficiaries: any[] | undefined; spouses: any[] | undefined; remittances: any[] | undefined; 
     * people: any[] | undefined; memberships: any[] | undefined; employments: any[] | undefined; details: any[] | undefined; adjustments: any[] | undefined; employers: any[] | undefined;}}
     */
    data;

    /**
     * uses configured entities to load data, and stores the data in the cache
     * @param {"beneficiaries" | "spouses" | "remittances" | "people" | "memberships" | "employments" | "details" | "adjustments" | "employers"} key 
     * @param {*} options 
     * @returns {Promise<any[]>}
     */
    async load (key, options) {
        /** @type {Service | undefined} */
        const service = this.entities[key];
        let loadedData = (await service?.loadList(this.data[key], options)) ?? [];
        await this.storeData(key, loadedData.filter(item => item !== undefined));
        return loadedData;
    }

    /**
     * 
     * @param {string} key 
     * @param {any[] | undefined} data 
     */
    async storeData (key, data = []) {
        if (this.entities[key].useCache) {
            if (this.entities[key].customCache) await this.entities[key].customCache(data);
            else {
                for (let item of data) {
                    if (item.keyValue) {
                        this.entities[key].useCache(item.keyValue, () => Promise.resolve(item), true);
                    }
                }
            }
        }
    }
}