import { date } from 'exceljs/dist/exceljs'
import { Definition, Ref, RefHistorical } from '../../framework/infra'
import { sum, getSafe } from '../../framework/utils/helper'
import config from '../../utils/config'
import RefundAccounts from '../pension/refund/RefundAccounts'
import { MembershipMessages } from './MembershipMessages'
import MembershipTasks from './MembershipTasks'
import moment from "moment"


const { YESNO, CHOICE, STRING } = Definition.types

export default class Membership extends Ref {
    get status() {
        return this.lastParticipation.desc
    }
    get lastParticipation() { 
        return this.participations && this.participations.last
    }
    get lastEnrolledParticipation() { 
        return this.participations?.findLast(pp => pp.joinDt !== "");
    }
    get combinedWarnings() { return RefHistorical.combine([this.messages, this.tasks]) } //messages and tasks

    get vestedParticipations () {
        return this.participations.all.filter(x=>x.status.isActive()|| x.status.isDeferred());
    }

    existNonSuspendedPensionForJur(jur) {
        return false //TODO
    }
    
    getEmployment(erId, ppNo) {
        return getSafe(this, 'participations.' + this.person.id + '_' + ppNo + '.' + erId + '_' + this.person.id + this.person.id + '_' + ppNo)
    }
    hasNoBeneficiary() { return this.beneficiaries.length === 0 }
    hasNoLegacyBeneficiary() { return !this.legacyBeneficiaries }
    /**
     * Whitelist of the Membership {@link definitions} properties to include in the payload
     * @returns {['person', 'participations']}
     */
    getPayloadInclusions() { return ['person', 'participations']};

    //checks if member is receiving pension
    isReceivingPension(date, jurisdiction) {
        //depending on date (pre-plan merger), we might want to make sure they received pension in the same jurisdiction
        let checkJurisdiction = true;
        if (moment(date).valueOf() >= moment(config.cutoff2023).valueOf()) checkJurisdiction = false;
        return this.participations.find(pp => (pp.status.isReceivingPension() || pp.isPendingPensioner()) && 
            (!checkJurisdiction || (checkJurisdiction && pp.employments?.last?.employer?.plan?.jurisdiction === jurisdiction))) ;
    } 

    static key = 'person'
    static definitions = {
        person: { ref: require('../person/Person') },
        participations: {  map: true, isListRef: true, ref: require('./participation/Participation'), text: 'Particiaptions', text_fr: 'Particiaptions' },
        vestedParticipations: { abstract: true },
        refundAccounts: {ref: RefundAccounts, text: 'Bank Account'},
        messages: { ref: MembershipMessages, text: 'Messages'},
        tasks: { ref: MembershipTasks, text: 'Tasks'},
        entitledDeathBenefits: { type: CHOICE, text: 'Entitled to Death Benefits', text_fr: 'Entitled to Death Benefits', options: [
            { key: 'spouse', text: 'Legal Spouse', text_fr: 'Legal Spouse'},
            { key: 'estate', text: 'Beneficiary/Estate', text_fr: 'Beneficiary/Estate'}
        ]}, 
        contacts: { ref: require('../pension/member/Contacts'), text: 'Third Party Contact' },
        complianceIssue: { type: STRING, text: 'Compliance Issue'},
        otherIssue: { type: STRING, text: 'Other' },
        
        status: { abstract: true, type: STRING, text: 'Status' },
        beneficiaries: { ref: require('../pension/member/Beneficiaries'), text: 'Beneficiaries' },
        lastParticipation: { abstract: true, ref: require('./participation/Participation') },
        lastEnrolledParticipation: { abstract: true, ref: require('./participation/Participation') },
    }
}



