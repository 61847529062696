import { ParticipationMessage, ParticipationTask } from "../entities"
import { ParticipationMessageConfig } from "../entities/membership/participation/ParticipationMessageConfig"
import { ParticipationTaskConfig } from "../entities/membership/participation/ParticipationTaskConfig"
import { Business } from "../framework/infra"
import { moment } from '../framework/utils/helper'

export default class ParticipationBusiness extends Business {
    static validateMessage(participation, eventCode) {
        const codeList = eventCode ? [eventCode] : ParticipationMessage.validationMessage();
        codeList.forEach(mess => {
            const code = mess.key;
            participation.messages.remove(mess => mess.code === code)
            if (ParticipationMessageConfig[code]?.validation(participation)) {
                participation.messages.pushNew({code: code})
            }
        })

    }


    /**
     * Validate all the participation's tasks.
     * 
     * Sets the `tasks` in the participation.
     * @param {Participation} participation 
     * @param {boolean | undefined} isLastEmployment Flag to indicate the participation tasks are viewed in the context of an employment: `true` means it's in the context of an employment that is the last employment, `false` means it's in the context of an employment that is not the last employment, `undefined` means it's not in the context of an employment.
     */
    static validateTask(participation, isLastEmployment){
        participation.tasks.reset();
        const validationTask = ParticipationTask.validationTask();
        validationTask.forEach(validTask => {
            const code = validTask.key;
            const task = participation.tasks.find(message => message.code === code);
                if(!task){
                    if(isLastEmployment !== false && ParticipationTaskConfig[code].validation(participation) && 
                    !(
                        (ParticipationTaskConfig[code].removeValidation && ParticipationTaskConfig[code].removeValidation(participation)) ||
                        (ParticipationTaskConfig[code].nextTask &&  ParticipationTaskConfig[ParticipationTaskConfig[code].nextTask].validation(participation))
                    )){
                        return participation.tasks.pushNew({code: code, params: ParticipationTaskConfig[code].params ? ParticipationTaskConfig[code].params(participation): []})
                    }
                }else{
                    if(isLastEmployment === false){
                        participation.tasks.remove(message => message.code === code)
                    } else {
                        if(ParticipationTaskConfig[code].removeValidation){
                            if(ParticipationTaskConfig[code].removeValidation(participation)) participation.tasks.remove(message => message.code === code)
                        }else if(ParticipationTaskConfig[code].nextTask){ //If the next task validation is true, remove the previous task. 
                            if(ParticipationTaskConfig[ParticipationTaskConfig[code].nextTask].validation(participation)){
                                participation.tasks.remove(
                                    (message) => message.code === code
                                );
                            }
                        }else{
                            if(!ParticipationTaskConfig[code].validation(participation)) participation.tasks.remove(message => message.code === code)
                        }
                    }
                }
        })

        participation.tasks = this.getTasksToShow(participation);
    }
   
    static validate(participation, isLastEmployment) {
        this.validateMessage(participation)
        this.validateTask(participation, isLastEmployment)
        return participation
    }

    /**
     * Get all the participation tasks to show, depending on the participation's status.
     * 
     * If participation status is `shouldShowTask`, returns all tasks.
     * Otherwise, return only the tasks that are `alwaysShow`
     */
    static getTasksToShow(participation) {
        if(participation?.lastStatusEvent?.status?.shouldShowTask?.()){
            return participation.tasks;
        }
        else {
            return participation.tasks.getFiltered((task) => task.config.alwaysShow);
        }
    }

}
    