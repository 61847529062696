import { Definition, Ref, RefDated } from '../../framework/infra'
import Person from './Person'
import SpouseHistoryUtil from './SpouseHistoryUtil'
const { DATE, CHOICE, STRING, BOOLEAN } = Definition.types

export default class Spouse extends RefDated {
    get maritalStatus() { return Spouse.getDefinition('mSts')?.getOptionText(this.mSts) ?? '' }
    get taxStatus() { return Spouse.getDefinition('taxationStatus')?.getOptionText(this.taxationStatus) ?? '' }
    
    get isSingle() { return SpouseHistoryUtil.isSingle(this.mSts) };
    get isSingleNotUndefined() { return SpouseHistoryUtil.isSingleNotUndefined(this.mSts) };
    get isMarried() { return SpouseHistoryUtil.isMarried(this.mSts) };
    get isCommonLaw() { return SpouseHistoryUtil.isCommonLaw(this.mSts) };
    get isSeparated() { return SpouseHistoryUtil.isSeparated(this.mSts) };
    get isDivorced() { return SpouseHistoryUtil.isDivorced(this.mSts) };
    get isWidowed() { return SpouseHistoryUtil.isWidowed(this.mSts) };

    get nameFirstLast() { return this.noSin ? this.unknownPerson.nameFirstLast : this.person.nameFirstLast }
    get dob() { return this.noSin ? this.unknownPerson.dob : this.person.dob }
    get sin() { return this.noSin ? "" : this.person.sin }

    static definitions = {
        sin: { abstract: true, type: Definition.types.SIN, text: 'SIN', text_fr: 'NAS' },
        person: { ref: require('./Person') },
        unknownPerson: { ref: require('./UnknownPerson') },
        noSin: { type: BOOLEAN },
        name: { abstract: true, type: STRING, text: 'Name' },
        nameFirstLast: { abstract: true, type: STRING, text: 'Name' },
        dob: { abstract: true, type: DATE, text: 'Date of Birth' },
        maritalStatusDeclarationReceived: { type: DATE, text: 'Judgement Received' },
        maritalBreakDownDate: { type: DATE, text: 'Breakdown Date' },
        maritalBreakDownOption: { type: CHOICE, text: 'Marital Breakdown', options: [
            { key: '', text: '', text_fr: ''},
            { key: 'settled', text: 'Yes - Settled', flag: { color: '#B00'}},
            { key: 'Pending', text: 'Yes - Pending', flag: { color: '#B00'}},
            { key: 'maybe', text: 'Data Conflict', flag: { color: '#ffc107'}},
        ]},
        judgementOrderReceivedOption: { type: CHOICE, text: 'Reallocation of Assets', options: [
            { key: '', text: '', text_fr: ''},
            { key: 'noAssigned', text: 'Nothing Assigned'},
            { key: 'assignedSettled', text: 'Portion Assigned-Payment Settled'},
            { key: 'assignedNoSettled', text: 'Portion Assigned-Payment Not Settled'},
        ]},
        judgementOrderReceived: { type: STRING, text: 'Judgement/Order Received' },
        taxationStatus: { type: CHOICE, text: 'Taxation Status', options: [
            { key: 'taxExempt', text: 'Tax-Exempt'},
            { key: 'taxable', text: 'Taxable', text_fr: 'Yes-Pending'},
        ]},
        taxStatus: { abstract: true, type: STRING, text: "Spouse Taxation Status" },
        jurisdiction: { type: CHOICE, text: 'Jurisdiction', options: [
            { key: 'fed', text: 'Federal'},
            { key: 'que', text: 'Quebec'},
            { key: 'both', text: 'Federal & Quebec'},
        ]},
        maritalStatus: { abstract: true, type: Definition.types.STRING, text: 'Marital Status' },
        mSts: { type: Definition.types.CHOICE, text: 'Marital Status', text_fr: 'Marital Status',
            options: [
            { key: 'single', text: 'Single', text_fr: 'Celibatere'},
            { key: 'married', text: 'Married', text_fr: 'Marrié/e'},
            { key: 'commonLaw', text: 'Common Law', text_fr: 'Conjoint'},
            { key: 'separated', text: 'Separated', text_fr: 'Separé/é'},
            { key: 'divorced', text: 'Divorced', text_fr: 'divorcé/é'},
            { key: 'widow', text: 'Widowed', text_fr: 'Veuve/Veuf'}
            ],
            filterOptions: (options, instance) => {
                // if instance is new or instance is not separated, do not show the "separated" option
                const filtered = (!instance || (instance instanceof Spouse && (instance.isNew() || !instance.isSeparated ))) ?
                    options?.filter(opt => opt.key !== 'separated')
                    : options;
                    return filtered;
            }
        },
        id: { type: Definition.types.STRING },
        parent: { type: Definition.types.STRING },
    }

    static key = 'id'
}


