import { RemittanceDetails, Participation } from "../entities";
import { ParticipationService, EmploymentService } from "../services";
import Mapper from "./Mapper";

/**
 * Map the data to {@link RemittanceDetails}
 * 
 * Loads the spouse, people, remittances, beneficiaries, memberships, employments
 * 
 * @extends {Mapper}
 */
export default class DetailListMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.details = new RemittanceDetails();
    }

    /** @type {RemittanceDetails} */
    details;

    /**
     * Loads the spouse, people, remittances, beneficiaries, memberships, employments
     * @returns {RemittanceDetails}
     */
    async map () {
        await this.load('spouses');
        await this.load('people');
        await this.load('remittances');
        await this.load('beneficiaries');
        await this.load('memberships');
        await this.load('employments');
        const loadedDetails = await this.load('details');
        // load the detail's participation (and fetches all participation's employments)
        for(var i = 0; i < loadedDetails.length; i++ ){
            const detail = loadedDetails[i];
            /** this details's employment's participation */
            const employmentParticipation = detail?.employment?.participation;
            /** 
             * all the employers in this details's employment's participation
             * @type {string[] | undefined}
             * */
            const employmentParticipationEmployers = employmentParticipation?.employers;
            /** The key value of the loaded employment's participation */
            const ppKeyValue = employmentParticipation?.keyValue;
            /** true if this employment's participation has multiple employments */
            const ppHasMultipleEmployments = Boolean(employmentParticipation?.isMer);
            if(employmentParticipationEmployers?.length && detail?.participation && !detail.participation.employers?.length){
                // employment in this detail has a participation with multiple employers, and participation in this detail is empty
                // get the participation and fetch its employments
                try{
                    const foundParticipation = await ParticipationService.get(ppKeyValue);
                    if(!foundParticipation) {
                        console.warn('DetailListMapper map participation not found with pp key', ppKeyValue);
                    } else {
                        detail.participation = foundParticipation;
                        if(employmentParticipationEmployers.some(employerId => !detail.participation?.employments?.all?.some(emp => emp.employer.id === employerId))){
                            // some employers are missing in the detail's participation's employments
                            /** @type {Employments} */
                            const foundEmployments = await EmploymentService.getParticipationEmployments(ppKeyValue);
                            if(!foundEmployments){
                                console.warn('DetailListMapper map employments not found with pp key', ppKeyValue);
                            }else {
                                detail.participation.employments = foundEmployments;
                            }
                        }
                    }
                } catch(err){
                    console.error('DetailListMapper map error', err)
                }
            }
        }

        this.details.pushList(loadedDetails);
        return this.details;
    }
}