import React from 'react'
import { Modal, Row } from '../../framework/containers';
import { Button } from '../../framework/controls';
import { Employment, EmploymentTask } from '../../entities/employment';
import { EmploymentBusiness, MembershipBusiness, ParticipationBusiness } from '../../business';
import { EmploymentService, MembershipService } from '../../services';

const TaskCard = ({task, employment, onFinalize}) => {

	const taskType = task instanceof EmploymentTask ? "Employment" : "Participation";
	const obj = task instanceof EmploymentTask ? employment : employment.participation;

	const [fixer, setFixer] = React.useState(null);


	const handleSelect = () => { 
		const props = task.form.props;
		let fixer = React.cloneElement(task.form, {
			startDate: props.definedDate ? task.params[props.definedDate].value : undefined,
			instance: props.isEmployment ? employment : employment.participation,
			onClose: () => setFixer(null),
			onSave: handleSave,
			task: task,
		})
		setFixer(fixer);
	}

	const handleSave = async (instance) => { 
		MembershipBusiness.reassignNestedParticipations(employment.participation.membership);
		EmploymentBusiness.validate(employment);
		ParticipationBusiness.validate(employment.participation, employment.participation?.isLastEmployment?.(employment));

		const isEmployment = instance instanceof Employment;
		if (isEmployment) { 
			await EmploymentService.updateEmployment(instance);
		} else {
			await MembershipService.update(instance.membership);
		}

		setFixer(null)
		onFinalize();
	}

	let taskDesc = task.desc;
	if (task.config.isMultipleResolution) {
		taskDesc = obj.getFlowStatusDesc(obj.events.findOrCreate('code', { code: task.code }));
	}

	return <div className={"transfer-card"} key={task.code}>
		<div className="transfer-cols">
			<div>
				<div className='info-label'>{taskType} Task</div>
				<div>{taskDesc}</div>
			</div>
			<div className={"transfer-card-col"}>
			{task.hasFlow() &&<Button className={'btn-primary'} onClick={handleSelect}>
					Resolve
				</Button> }
			</div> 
		</div>
		{fixer && <Modal className='h-100 w-70 modal-bg-color'>
			{fixer}
		</Modal>}
	</div>
}

const TaskList = ({employment, onHide}) => {

	const finalize = () => {
		if (employment.tasks.all.length === 0 && employment.participation.tasks.all.length === 0) {
			onHide();
		}
	}

	return <>
        <div className='transfer-detail'>
            {[...employment.tasks.all, 
			  ...employment.participation.tasks.all
			].map((task) => 
				<TaskCard 
					employment={employment} 
					onFinalize={finalize} 
					task={task} 
					key={task.code} 
				/>
			)}
        </div>
        <Row className='justify-content-end button-row mt15'> 
            <Button key="export" className='btn-secondary' onClick={onHide}>Close</Button>
        </Row>
	</>
}

export default TaskList;